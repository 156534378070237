.img-footer {
    width: 100%;
    height: auto;
}

.Footer > p {
    color: grey;
    font-size: 0.7rem;
    margin-bottom: 0px;
    padding: 4px;
}
